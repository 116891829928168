import { useState } from "react";

import { Lines } from "./Lines";

import { Column, Row, useWindowSize } from "./utils/chakra";
import { Logo, Robot } from "./utils/icons";
import { Box, Button, Text, Tooltip } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { string_to_unicode_variant } from "string-to-unicode-variant";

import jetskiImage from "./images/jetski.png";

import { LiveState, forwardStateTo } from "./utils/sync";
import { GameConfig, computeMassAfterJumpingLine, mapMassToVelocity } from "./utils/game/configLib";
import { useEffect } from "react";
import { useAccount } from "wagmi";
import { useMUD } from "./MUDContext";
import { useOnchainButton } from "./utils/controls";
import { toEntityId, computeX, EntityType } from "./utils/game/entityLib";
import { GameButton } from "./utils/button";
import { AccountButton, useAppAccountClient } from "@latticexyz/account-kit";
import { mapEntityToEmoji } from "./utils/debugging";
import { chainTime, msToWad } from "./utils/timeLib";
import { centerLineInViewport } from "./utils/lineUI";
import { insertExistingEntityIntoLine, removeEntityFromLine } from "./utils/game/jumpLib";
import { findBestRightSpawnNeighbor } from "./utils/spawn";
import { sum } from "./utils/bigintMinHeap";

import { startBackgroundMusic, stopBackgroundMusic } from "./utils/music";

export function GameUI({
  liveState,
  gameConfig,
  setLiveState,
}: {
  liveState: LiveState;
  gameConfig: GameConfig;
  setLiveState: React.Dispatch<React.SetStateAction<LiveState>>;
}) {
  const {
    systemCalls,
    network: { publicClient },
  } = useMUD();

  const { width } = useWindowSize();
  const isMobile = width < 768;

  const client = useAppAccountClient();

  const { address: userAddress } = useAccount();

  const userEntity = userAddress
    ? liveState.lines.flat().find((entity) => entity.entityId === toEntityId(BigInt(userAddress)))
    : undefined;

  useEffect(() => {
    if (userEntity?.etype === EntityType.ALIVE) {
      startBackgroundMusic(false);
    } else {
      stopBackgroundMusic();
    }
  }, [userEntity?.etype]);

  const [expectedSpawnLocation, setExpectedSpawnLocation] = useState<{
    lineId: number;
    x: number;
  } | null>(null);
  const { onClick: handleSpawn, expectedInclusionTime: spawnExpectedInclusionTime } =
    useOnchainButton(
      async (stopLoadingEarly, [, expectedInclusionBlockTimestampWad]: [number, bigint]) => {
        if (userEntity || !client) return;

        startBackgroundMusic(true);

        const worstCaseInclusionTimestampWad = expectedInclusionBlockTimestampWad + msToWad(2000);

        const predictedState = forwardStateTo(liveState, gameConfig, false, null, {
          stopAtIteration: null,
          stopAtTimestampWad: worstCaseInclusionTimestampWad,
        });

        const lineId = Math.floor(Math.random() * predictedState.gameState.numLines);

        centerLineInViewport(lineId);

        const spawnRightNeighbor = findBestRightSpawnNeighbor(
          predictedState.lines[lineId],
          worstCaseInclusionTimestampWad,
          gameConfig
        );

        if (!spawnRightNeighbor)
          throw new Error("No valid right neighbor to spawn next to found! Try again.");

        console.log(`Spawning to the right of:`, mapEntityToEmoji(spawnRightNeighbor.entityId));

        const velRight = spawnRightNeighbor.etype !== EntityType.WALL; // Run away from walls, and into the rightmost entity.

        setExpectedSpawnLocation({
          lineId,
          x: Math.max(
            0,
            Math.min(
              gameConfig.lineWidth.fromWad(),
              computeX(
                spawnRightNeighbor,
                expectedInclusionBlockTimestampWad,
                gameConfig.velocityCoefficient
              ).fromWad() +
                (!velRight
                  ? -1 *
                    mapMassToVelocity(
                      gameConfig.playerStartingMass,
                      gameConfig.velocityCoefficient
                    ).fromWad()
                  : 0)
            )
          ),
        });

        // Don't really see a point in preempting this, since a failure + rollback
        // is a way worse UX risk than 250ms of latency or whatever from spawning.
        const MAX_SPAWN_RETIRES = 5;
        let retries = 0;
        while (retries < MAX_SPAWN_RETIRES) {
          try {
            console.log(
              "Expecting to spawn at:",
              worstCaseInclusionTimestampWad.fromWad().toFixed(0)
            );
            const res = await systemCalls.spawn(
              client,
              lineId,
              spawnRightNeighbor.entityId,
              velRight
            );
            publicClient.getBlock({ blockNumber: res.blockNumber }).then((block) => {
              console.log(
                `Truly spawned at:`,
                Number(block.timestamp),
                ` (block #${block.number})`
              );
            });
            break;
          } catch (error) {
            console.error(`Spawn attempt ${retries + 1} failed:`, error);
            retries++;
            if (retries === MAX_SPAWN_RETIRES) {
              console.error("Max retries reached. Spawn failed.");
              throw error;
            }
          }
          await new Promise((resolve) => setTimeout(resolve, 250));
        }

        new Audio("sounds/spawn.wav").play();

        setExpectedSpawnLocation(null);
      }
    );

  const setDirection = async (
    velRight: boolean,
    stopLoadingEarly: () => void,
    [expectedInclusionBlockTimestamp, expectedInclusionBlockTimestampWad]: [number, bigint]
  ) => {
    if (!userEntity || !client) return;

    new Audio("sounds/direction.wav").play();

    // random tx id
    const txId = Math.random().toString(36).substring(2, 15);

    console.log(`Expecting <${txId}> setDirection at:`, expectedInclusionBlockTimestamp);

    // These are all used to resolve whether to rollback state if the tx fails,
    // and/or whether to cancel the preemptive ui update if the tx fails quickly.
    let txLanded = false;
    let preempted = false;

    // Since there is latency from when a block is mined to when the frontend receives
    // it, we preemptively switch the user's direction at the expected block timestamp.
    setTimeout(
      () => {
        if (txLanded) return; // If the tx landed and/or reverted already, don't try to preempt.
        setLiveState((prevState) => {
          if (txLanded) return prevState; // Just in case we somehow still get here even w/ the check above.

          const newState = structuredClone(prevState);

          const userEntity = newState.lines
            .flat()
            .find((entity) => entity.entityId === toEntityId(BigInt(userAddress!)));

          if (!userEntity) return prevState; // User died before the tx landed.

          preempted = true; // Set to true so we know to rollback if the actual tx fails.
          console.log("Preempted setDirection.");

          // Resolve position, to avoid retroactively updating the trajectory when we update velocity.
          userEntity.lastX = computeX(
            userEntity,
            expectedInclusionBlockTimestampWad,
            gameConfig.velocityCoefficient
          );
          userEntity.lastTouchedTime = expectedInclusionBlockTimestampWad;

          userEntity.velMultiplier = velRight
            ? userEntity.velMultiplier.abs()
            : -userEntity.velMultiplier.abs();

          return newState;
        });

        stopLoadingEarly(); // Stop the loading animation now that we've preempted.
      },
      expectedInclusionBlockTimestamp * 1000 - chainTime() // * 1000 to convert to ms
    );

    try {
      const res = await systemCalls.setDirection(client, velRight);
      txLanded = true; // Set to true in case preempt still hasn't happened.
      publicClient.getBlock({ blockNumber: res.blockNumber }).then((block) => {
        console.log(
          `Truly <${txId}> setDirection at:`,
          Number(block.timestamp),
          ` (block #${block.number})`
        );
        if (block.timestamp != BigInt(expectedInclusionBlockTimestamp))
          console.warn("setDirection included in unexpected block");
      });
    } catch (e: any) {
      txLanded = true; // Set to true in case preempt still hasn't happened.
      console.error(`<${txId}> setDirection failed, rolling back:`, e);
      // If we did end up preempting the block, we need to resync to ground truth.
      // We do this by making the last synced time diverge from the synced state.
      // This makes the core game interval think new synced state has arrived and
      // recompute the current state from last synced state again. All lines are set
      // w/ a negative lastTouchedTime so they are not skipped during recomputation.
      if (preempted)
        setLiveState((prev) => ({
          ...prev,
          lineStates: prev.lineStates.map((line) => ({ ...line, lastTouchedTime: -1n })),
          lastSyncedTime: -1,
        }));
      else console.warn("No setDirection preempt, skipped rollback");

      if (e.toString().includes("CALLER_IS_NOT_ALIVE")) return; // Suppress the error, as this will just confuse people.

      throw e; // Bubble it up again.
    }
  };

  const { onClick: handleRight, expectedInclusionTime: rightExpectedInclusionTime } =
    useOnchainButton(
      async (stopLoadingEarly, expectedNextBlockTimestampArray: [number, bigint]) => {
        await setDirection(true, stopLoadingEarly, expectedNextBlockTimestampArray);
      }
    );

  const { onClick: handleLeft, expectedInclusionTime: leftExpectedInclusionTime } =
    useOnchainButton(
      async (stopLoadingEarly, expectedNextBlockTimestampArray: [number, bigint]) => {
        await setDirection(false, stopLoadingEarly, expectedNextBlockTimestampArray);
      }
    );

  const jump = async (
    direction: "up" | "down",
    stopLoadingEarly: () => void,
    [expectedInclusionBlockTimestamp, expectedInclusionBlockTimestampWad]: [number, bigint]
  ) => {
    if (!userEntity || !client) return;

    new Audio(direction === "up" ? "sounds/up.wav" : "sounds/down.wav").play();

    // random tx id
    const txId = Math.random().toString(36).substring(2, 15);

    console.log(
      `Expecting <${txId}> jumpToLine(${direction}) at:`,
      expectedInclusionBlockTimestamp
    );

    const currentLineId = userEntity.lineId;
    centerLineInViewport(
      direction === "up"
        ? currentLineId === 0
          ? liveState.gameState.numLines - 1
          : currentLineId - 1
        : currentLineId === liveState.gameState.numLines - 1
          ? 0
          : currentLineId + 1
    ); // Scroll to the new line.

    // These are all used to resolve whether to rollback state if the tx fails,
    // and/or whether to cancel the preemptive ui update if the tx fails quickly.
    let txLanded = false;
    let preempted = false;

    // Since there is latency from when a block is mined to when the frontend receives
    // it, we preemptively switch the user's direction at the expected block timestamp.
    setTimeout(
      () => {
        if (txLanded) return; // If the tx landed and/or reverted already, don't try to preempt.
        setLiveState((prevState) => {
          if (txLanded) return prevState; // Just in case we somehow still get here even w/ the check above.

          const newState = structuredClone(prevState);

          const userEntity = newState.lines
            .flat()
            .find((entity) => entity.entityId === toEntityId(BigInt(userAddress!)));

          if (!userEntity) return prevState; // User died before the tx landed.

          const currentLineId = userEntity.lineId;
          const currentLine = newState.lines[currentLineId];

          const newLineId =
            direction === "up"
              ? currentLineId === 0
                ? newState.gameState.numLines - 1
                : currentLineId - 1
              : currentLineId === newState.gameState.numLines - 1
                ? 0
                : currentLineId + 1;
          const newLine = newState.lines[newLineId];

          preempted = true; // Set to true so we know to rollback if the actual tx fails.
          console.log("Preempted jump.");

          // 1) Remove the entity from the old line:

          const currentLineCollisionQueue = newState.lineStates[currentLineId].collisionQueue;

          // Touch the caller to ensure we don't change its trajectory retroactively.
          // We could check Location.getLastTouchedTime(caller) != timeWad() first to
          // avoid touching the caller if it's already been touched, but in practice
          // it's very unlikely the caller had a collision at the exact calling time.
          userEntity.lastX = computeX(
            userEntity,
            expectedInclusionBlockTimestampWad,
            gameConfig.velocityCoefficient
          );
          userEntity.lastTouchedTime = expectedInclusionBlockTimestampWad;

          removeEntityFromLine(
            currentLine,
            userEntity,
            currentLineCollisionQueue,
            gameConfig.velocityCoefficient
          );

          // 1.5) Decay the entity's mass according to the line jump decay factor:

          // Mass decays by 1 - lineJumpDecayFactor whenever an entity jumps lines.
          const newMass = computeMassAfterJumpingLine(
            userEntity.mass,
            gameConfig.lineJumpDecayFactor
          );

          // We don't want to allow players to get too small and speedy.
          if (newMass < gameConfig.minFoodMass) return prevState;

          userEntity.mass = newMass;

          // 2) Insert the entity into the new line:

          const newLineCollisionQueue = newState.lineStates[newLineId].collisionQueue;

          try {
            insertExistingEntityIntoLine(
              newLine,
              userEntity,
              expectedInclusionBlockTimestampWad,
              newLineCollisionQueue,
              newState.gameState,
              gameConfig,
              true,
              userEntity.entityId
            );
          } catch (e: any) {
            console.error(
              "Failed to preempt inserting entity into line, not going to apply changes",
              e
            );

            return prevState;
          }

          return newState;
        });

        stopLoadingEarly(); // Stop the loading animation now that we've preempted.
      },
      expectedInclusionBlockTimestamp * 1000 - chainTime() // * 1000 to convert to ms
    );

    try {
      const res = await systemCalls.jumpToLine(client, direction === "up");
      txLanded = true; // Set to true in case preempt still hasn't happened.
      publicClient.getBlock({ blockNumber: res.blockNumber }).then((block) => {
        console.log(
          `Truly <${txId}> jumpToLine at:`,
          Number(block.timestamp),
          ` (block #${block.number})`
        );
        if (block.timestamp != BigInt(expectedInclusionBlockTimestamp))
          console.warn("jumpToLine included in unexpected block");
      });
    } catch (e: any) {
      txLanded = true; // Set to true in case preempt still hasn't happened.
      console.error(`<${txId}> jumpToLine failed, rolling back:`, e);
      // If we did end up preempting the block, we need to resync to ground truth.
      // We do this by making the last synced time diverge from the synced state.
      // This makes the core game interval think new synced state has arrived and
      // recompute the current state from last synced state again. All lines are set
      // w/ a negative lastTouchedTime so they are not skipped during recomputation.
      if (preempted)
        setLiveState((prev) => ({
          ...prev,
          lineStates: prev.lineStates.map((line) => ({ ...line, lastTouchedTime: -1n })),
          lastSyncedTime: -1,
        }));
      else console.warn("No jumpToLine preempt, skipped rollback");

      centerLineInViewport(currentLineId); // Scroll back to the previous line.

      if (e.toString().includes("WOULD_OVERLAP_WITH_UNCONSUMABLE_ENTITY"))
        throw new Error("You would have died instantly after jumping.");

      if (e.toString().includes("CALLER_IS_NOT_ALIVE")) return; // Suppress the error, as this will just confuse people.

      throw e; // Bubble it up again.
    }
  };

  const { onClick: handleJumpDown, expectedInclusionTime: jumpDownExpectedInclusionTime } =
    useOnchainButton(
      async (stopLoadingEarly, expectedNextBlockTimestampArray: [number, bigint]) => {
        if (jumpUpExpectedInclusionTime != null) return;

        await jump("down", stopLoadingEarly, expectedNextBlockTimestampArray);
      }
    );

  const { onClick: handleJumpUp, expectedInclusionTime: jumpUpExpectedInclusionTime } =
    useOnchainButton(
      async (stopLoadingEarly, expectedNextBlockTimestampArray: [number, bigint]) => {
        if (jumpDownExpectedInclusionTime != null) return;

        await jump("up", stopLoadingEarly, expectedNextBlockTimestampArray);
      }
    );

  const {
    onClick: handleChooseUsername,
    expectedInclusionTime: chooseUsernameExpectedInclusionTime,
  } = useOnchainButton(async () => {
    let username;
    do {
      username = prompt(
        `Choose a username (must be 5 characters or less):\n
${string_to_unicode_variant(`By continuing, you agree to the terms of service at: ${window.location.origin.replace("https://", "").replace("http://", "").replace("www.", "")}/terms`, "bs")}`
      );
      if (!username) return; // User cancelled.
      if (username.length > 5) alert("Username must be 5 characters or less. Please try again.");
    } while (username.length > 5);

    await systemCalls.setUsername(client, username);
  });

  const handleSpawnWithUsername = async () => {
    if (!userAddress || !client) return;

    try {
      if (!liveState.gameState.usernames.get(toEntityId(BigInt(userAddress)))) {
        console.log("User has no username, prompting them to choose one...");
        await handleChooseUsername();
      }
    } catch (e) {
      console.warn("Failed to set username before spawning:", e);
    } finally {
      await handleSpawn();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "s") {
        event.preventDefault();
        handleSpawnWithUsername();
      } else if (event.key === "ArrowLeft") {
        event.preventDefault();
        handleLeft();
      } else if (event.key === "ArrowRight") {
        event.preventDefault();
        handleRight();
      } else if (event.key === "ArrowDown") {
        event.preventDefault();
        handleJumpDown();
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        handleJumpUp();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleSpawnWithUsername, handleLeft, handleRight, handleJumpDown, handleJumpUp]);

  return (
    <>
      <Column
        mainAxisAlignment="flex-start"
        crossAxisAlignment="center"
        height="100%"
        width={{ base: "100%", xl: "80%" }}
        px={8}
      >
        <Row
          mainAxisAlignment={isMobile ? "center" : "flex-start"}
          crossAxisAlignment="flex-start"
          paddingTop="32px"
          width="100%"
        >
          <Box mr={isMobile ? "0px" : "auto"}>
            <Logo height="45px" />
          </Box>

          {client && !isMobile ? (
            <GameButton
              onClick={(e) => {
                e.currentTarget.blur(); // Don't stay selected.
                handleChooseUsername();
              }}
              bg="#262626"
              borderColor="#404040"
              borderRight="0px"
              _hover={{
                backgroundColor: "#404040",
              }}
            >
              {userAddress
                ? liveState.gameState.usernames.get(toEntityId(BigInt(userAddress))) ??
                  "Set Username"
                : "Set Username"}
            </GameButton>
          ) : null}
          {!isMobile ? <AccountButton /> : null}
        </Row>

        {isMobile ? (
          <Text textAlign="center" mt={8}>
            AgariOP is spectator-only on mobile. Switch to a computer to play.
          </Text>
        ) : null}

        <Row
          mainAxisAlignment="space-between"
          crossAxisAlignment="flex-start"
          width="100%"
          height="100%"
        >
          {!isMobile ? (
            <Column
              mainAxisAlignment="flex-start"
              crossAxisAlignment="center"
              maxWidth="269px"
              minWidth="269px"
              overflow="auto"
              className="disableScrollBar fadeBottom"
              height={`calc(100vh - 109px)`} // paddingTop(32px) + logoBarHeight(45px) + logoBarPaddingTop(32px)
              marginTop="32px"
              mr={8}
            >
              <Box border="1px" borderColor="#1A1A1A" backgroundColor="#0D0D0D" width="100%" p={4}>
                <Text fontWeight="bold" fontSize="lg">
                  PLAY NOW
                </Text>
                <Text fontSize="sm" mt={2}>
                  AgariOP is a real-time on-chain game, built on <u>MUD</u> and <u>OP Stack</u>.
                  Earn your rank by eating food, but avoid hitting a wall or larger player.
                </Text>

                <Button
                  mt={4}
                  backgroundColor="#00FF99"
                  borderRadius="0"
                  width="100%"
                  height="42px"
                  color="#0D0D0D"
                  _hover={{
                    backgroundColor: "#00E893",
                    animation: "none",
                  }}
                  _disabled={{
                    opacity: 0.1,
                    animation: "none",
                  }}
                  p={4}
                  onClick={!client ? () => alert("Sign in first!") : handleSpawnWithUsername}
                  isDisabled={userEntity != undefined || spawnExpectedInclusionTime != null}
                  animation={client ? "blinkMild 0.6s infinite ease-out alternate" : "none"}
                >
                  {userEntity == undefined && spawnExpectedInclusionTime != null ? (
                    <>
                      SPAWNING YOU IN
                      <span className="dot-1">.</span>
                      <span className="dot-2">.</span>
                      <span className="dot-3">.</span>
                    </>
                  ) : (
                    "START PLAYING"
                  )}
                </Button>
              </Box>

              <Box
                border="1px"
                borderColor="#1A1A1A"
                backgroundColor="#0D0D0D"
                p={4}
                width="100%"
                mt={4}
              >
                <Column mainAxisAlignment="center" crossAxisAlignment="flex-start">
                  <Text fontWeight="bold" fontSize="lg" mb={3}>
                    CONTROLS
                  </Text>

                  <Row mainAxisAlignment="flex-start" crossAxisAlignment="center" width="100%">
                    <GameButton
                      onClick={handleLeft}
                      isDisabled={userEntity == undefined}
                      isLoading={userEntity != undefined && leftExpectedInclusionTime != null}
                      _disabled={{
                        opacity: 0.7,
                      }}
                    >
                      ←
                    </GameButton>

                    <GameButton
                      onClick={handleRight}
                      isDisabled={userEntity == undefined}
                      isLoading={userEntity != undefined && rightExpectedInclusionTime != null}
                      ml={2}
                      _disabled={{
                        opacity: 0.7,
                      }}
                    >
                      →
                    </GameButton>

                    <Text fontSize="15px" ml={3}>
                      Move <u>Left/Right</u>
                    </Text>
                  </Row>

                  <Row
                    mainAxisAlignment="flex-start"
                    crossAxisAlignment="center"
                    width="100%"
                    mt={2}
                  >
                    <GameButton
                      onClick={jumpUpExpectedInclusionTime != null ? () => {} : handleJumpUp} // Prevent double clicks.
                      isDisabled={userEntity == undefined || jumpDownExpectedInclusionTime != null}
                      isLoading={userEntity != undefined && jumpUpExpectedInclusionTime != null}
                      _disabled={{
                        opacity: 0.7,
                      }}
                    >
                      ↑
                    </GameButton>
                    <GameButton
                      ml={2}
                      onClick={jumpDownExpectedInclusionTime != null ? () => {} : handleJumpDown} // Prevent double clicks.
                      isDisabled={userEntity == undefined || jumpUpExpectedInclusionTime != null}
                      isLoading={userEntity != undefined && jumpDownExpectedInclusionTime != null}
                      _disabled={{ opacity: 0.7 }}
                    >
                      ↓
                    </GameButton>

                    <Text fontSize="15px" ml={3}>
                      Move <u>Up/Down</u>
                    </Text>
                  </Row>

                  <Row
                    mainAxisAlignment="flex-start"
                    crossAxisAlignment="center"
                    width="100%"
                    mt={2}
                  >
                    <GameButton
                      onClick={!client ? () => alert("Sign in first!") : handleSpawnWithUsername}
                      isDisabled={userEntity != undefined}
                      isLoading={userEntity == undefined && spawnExpectedInclusionTime != null}
                      _disabled={{
                        opacity: 0.7,
                      }}
                    >
                      S
                    </GameButton>

                    <Text fontSize="15px" ml={3}>
                      Spawn/Respawn
                    </Text>
                  </Row>

                  <Text fontSize="xs" mt={3} color="#808080">
                    Crab spinning means a tx is pending inclusion on-chain.
                  </Text>
                </Column>
              </Box>

              <Box
                border="1px"
                borderColor="#1A1A1A"
                backgroundColor="#0D0D0D"
                px={4}
                pb={4}
                width="100%"
                mt={4}
              >
                <img src={jetskiImage} width="100px" />
                <Text fontWeight="bold" fontSize="lg">
                  WIN A JETSKI
                </Text>
                <Text fontSize="sm" mt={2}>
                  The top player will receive a Ski-Doo 4000.
                </Text>

                <Text fontSize="xs" mt={2} color="#808080">
                  Game ends June 10th @ 9 AM PST.
                </Text>
              </Box>

              <Box
                border="1px"
                borderColor="#1A1A1A"
                backgroundColor="#0D0D0D"
                p={4}
                width="100%"
                mt={4}
                mb={4}
              >
                <Text fontWeight="bold" fontSize="lg">
                  WRITE A BOT
                </Text>
                <Text fontSize="sm" mt={2} color="#808080">
                  The best players are almost certainly not human...
                </Text>

                <Button
                  mt={4}
                  backgroundColor="#0D0D0D"
                  borderColor="#00FF99"
                  borderWidth="1.5px"
                  borderRadius="0"
                  width="100%"
                  height="42px"
                  color="#00FF99"
                  p={4}
                  _hover={{ opacity: 0.8 }}
                  _active={{ opacity: 0.35 }}
                  as="a"
                  href="https://github.com/transmissions11/agariop"
                  target="_blank"
                >
                  LEARN HOW{" "}
                  <Robot style={{ marginBottom: "2px", fill: "#00FF99", marginLeft: "12px" }} />
                </Button>
              </Box>
            </Column>
          ) : null}

          <Lines
            liveState={liveState}
            gameConfig={gameConfig}
            userEntity={userEntity}
            expectedSpawnLocation={expectedSpawnLocation}
            spawnExpectedInclusionTime={spawnExpectedInclusionTime}
            leftExpectedInclusionTime={leftExpectedInclusionTime}
            rightExpectedInclusionTime={rightExpectedInclusionTime}
            jumpDownExpectedInclusionTime={jumpDownExpectedInclusionTime}
            jumpUpExpectedInclusionTime={jumpUpExpectedInclusionTime}
          />
        </Row>
      </Column>

      <Column
        mainAxisAlignment="flex-start"
        crossAxisAlignment="center"
        height="100%"
        width="20%"
        borderLeft="1px"
        borderColor="#1A1A1A"
        display={{ base: "none", xl: "flex" }}
      >
        <Row
          mainAxisAlignment="space-between"
          crossAxisAlignment="center"
          height="65px"
          width="100%"
          borderBottom="1px"
          borderColor="#1A1A1A"
          px={8}
          color="#808080"
        >
          <Text fontWeight="bold">Player</Text>
          <Tooltip
            label={`Sum of your top ${gameConfig.highScoreTopK} lifetime scores. Each lifetime score = total mass consumed during that life.`}
            bg="#262626"
            fontFamily="BerkeleyMono, monospace"
            hasArrow
            mr={3}
            boxShadow="0 0 5px #262626"
          >
            <Text>Overall Score {width < 1440 ? null : <InfoOutlineIcon mb="3px" />}</Text>
          </Tooltip>
        </Row>

        <Column
          mainAxisAlignment="flex-start"
          crossAxisAlignment="center"
          height="100%"
          width="100%"
          overflowY="auto"
          className="disableScrollBar fadeBottom"
        >
          {Array.from(liveState.gameState.highScores)

            .map(([entityId, highScores]) => [entityId, sum(highScores)]) // Summed to turn k high scores into one ranking score.
            .filter(([_, score]) => score > 0n)
            .sort((a, b) => Number(b[1] - a[1])) // Descending order.
            .map(([entityId, score]) => {
              const isUserEntity = userAddress
                ? toEntityId(BigInt(userAddress)) === entityId
                : false;

              return (
                <Row
                  key={entityId.toString()}
                  mainAxisAlignment="space-between"
                  crossAxisAlignment="center"
                  width="100%"
                  minHeight="50px"
                  borderBottom="1px"
                  borderColor="#1A1A1A"
                  px={8}
                  _hover={{
                    backgroundColor: "#0D0D0d",
                  }}
                >
                  <Text color={isUserEntity ? "#00FF99" : "white"}>
                    {liveState.gameState.usernames.get(entityId) ??
                      ("UNKNOWN " + entityId.toString().slice(0, 4)).toUpperCase()}
                    <b>{isUserEntity ? " (You)" : ""}</b>
                  </Text>
                  <Text color={"#FF5700"}>{Math.floor(score.fromWad()).toLocaleString()}</Text>
                </Row>
              );
            })}
        </Column>
      </Column>
    </>
  );
}
