import React from "react";
import ReactDOM from "react-dom/client";

import { ChakraProvider } from "@chakra-ui/react";

import { App } from "./App";

import { setup } from "./mud/setup";
import { MUDProvider } from "./MUDContext";

// import mudConfig from "contracts/mud.config";

import "./styles.css";

import { WagmiProvider, createConfig } from "wagmi";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { supportedChains } from "./mud/supportedChains";
import { createClient, fallback, http, webSocket } from "viem";
import { RainbowKitProvider, darkTheme } from "@rainbow-me/rainbowkit";
import { AccountKitProvider } from "@latticexyz/account-kit";
import { getNetworkConfig } from "./mud/getNetworkConfig";

import "./utils/polyfills";
import "@rainbow-me/rainbowkit/styles.css";

// ----------------------------------------  LOGROCKET
import LogRocket from "logrocket";
console.log("🚀 Starting LogRocket...");
LogRocket.init("paradigm/agariop");

const queryClient = new QueryClient();

const wagmiConfig = createConfig({
  chains: supportedChains,
  client: ({ chain }) =>
    createClient({
      chain,
      // We intentionally don't use fallback+webSocket here because if a chain's RPC config
      // doesn't include a `webSocket` entry, it doesn't seem to fallback and instead just
      // ~never makes any requests and all queries seem to sit idle.
      transport: fallback([webSocket(), http()]),
      pollingInterval: 100,
      cacheTime: 100,
    }),
});

const networkConfig = getNetworkConfig();

const rootElement = document.getElementById("react-root");
if (!rootElement) throw new Error("React root not found");
const root = ReactDOM.createRoot(rootElement);

setup().then(async (result) => {
  root.render(
    <ChakraProvider>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            theme={darkTheme({ borderRadius: "none" })}
            initialChain={networkConfig.chain}
          >
            <AccountKitProvider
              config={{
                chainId: networkConfig.chain.id,
                worldAddress: networkConfig.worldAddress,
                erc4337: false,
                theme: "dark",
              }}
            >
              <MUDProvider value={result}>
                <App />
              </MUDProvider>
            </AccountKitProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </ChakraProvider>
  );

  // // https://vitejs.dev/guide/env-and-mode.html
  // if (import.meta.env.DEV) {
  //   const { mount: mountDevTools } = await import("@latticexyz/dev-tools");
  //   mountDevTools({
  //     config: mudConfig,
  //     publicClient: result.network.publicClient,
  //     walletClient: result.network.walletClient,
  //     latestBlock$: result.network.latestBlock$,
  //     storedBlockLogs$: result.network.storedBlockLogs$,
  //     worldAddress: result.network.worldContract.address,
  //     worldAbi: result.network.worldContract.abi,
  //     write$: result.network.write$,
  //     useStore: result.network.useStore,
  //   });
  // }
});
