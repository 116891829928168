import { MUDChain, mudFoundry, redstone, garnet } from "@latticexyz/common/chains";

import { defineChain } from "viem";

export const agariopAnvil = defineChain({
  id: 696969,
  name: "AgariOP Anvil",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.agariop.xyz"],
      webSocket: ["wss://rpc.agariop.xyz"],
    },
  },
  fees: {
    defaultPriorityFee: 0n,
  },
  indexerUrl: "https://indexer.agariop.xyz",

  // TODO: Add this:
  //   contracts: {
  //     multicall3: {
  //       address: "0xcA11bde05977b3631167028862bE2a173976CA11",
  //       blockCreated: 5882,
  //     },
  //   },
});

export const agariopConduit = defineChain({
  id: 38760,
  name: "AgariOP Conduit",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc-agariop-reth-t11s.t.conduit-stg.xyz"],
      webSocket: ["wss://rpc-agariop-reth-t11s.t.conduit-stg.xyz"],
    },
  },
  // indexerUrl: "https://indexer.agariop.xyz",
  // TODO: Add this:
  //   contracts: {
  //     multicall3: {
  //       address: "0xcA11bde05977b3631167028862bE2a173976CA11",
  //       blockCreated: 5882,
  //     },
  //   },
});

/*
 * See https://mud.dev/tutorials/minimal/deploy#run-the-user-interface
 * for instructions on how to add networks.
 */
export const supportedChains: readonly [MUDChain, ...MUDChain[]] = [
  mudFoundry,
  redstone,
  garnet,
  agariopAnvil,
  agariopConduit,
];
