import { CHAIN_ID } from "../mud/getNetworkConfig";
import { WAD } from "./bigint";

let CHAIN_TIMESTAMP_OFFSET_MS = Number.MIN_SAFE_INTEGER;

// chain time is offset by however many ms from the turn of the second
// the genesis block of the chain was mined at, as block timestamp is
// rounded down to seconds. also accounts for device clock being off.
export function chainTime(): number {
  return Date.now() + CHAIN_TIMESTAMP_OFFSET_MS;
}

async function syncClockOffset() {
  const computeClockOffset = async () => {
    const t0 = performance.now(); // ≈ timestamp of request packet transmission.
    let { chaintime, chainoffset }: { chaintime: number; chainoffset: number } = await (
      await fetch(`https://timeserver.agariop.xyz`, {
        priority: "high",
        cache: "no-store",
      })
    ).json();
    // We use performance.now() to measure round trip time because it's more accurate
    // than Date.now() and not impacted by user clock adjustments, clock skew, etc.
    const [roundTrip, clienttime] = [performance.now() - t0, Date.now()];

    // See: https://en.wikipedia.org/wiki/Network_Time_Protocol#Clock_synchronization_algorithm
    // serverClockTime is in seconds, clientClockTime/roundTrip is in milliseconds, so we convert.
    const clockOffset = chaintime - clienttime + roundTrip / 2;

    console.log("Net clock offset:", formatOffset(clockOffset));
    console.log("-> Chain offset:", formatOffset(chainoffset));
    console.log("-> System offset:", formatOffset(clockOffset - chainoffset));
    console.log("Timeserver round trip:", roundTrip.toFixed(2) + "ms");

    return { clockOffset, roundTrip };
  };

  if (Number(CHAIN_ID) === 31337) {
    console.log("Using local node, setting clock offset to 0.");
    CHAIN_TIMESTAMP_OFFSET_MS = 0;
  } else {
    try {
      for (let attempts = 0; attempts < 5; attempts++) {
        const { clockOffset, roundTrip } = await computeClockOffset();
        // If the round trip was sub 150ms, we'll accept the measurement.
        if (roundTrip < 150) {
          CHAIN_TIMESTAMP_OFFSET_MS = Math.floor(clockOffset);
          return;
        }
        // If it was longer, we'll try again, hopefully was just a transient issue.
        console.log("Round trip took dangerously long, retrying...");
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }

      // If we've reached this point, the round trip to the timeserver consistently took
      // more than 150ms. This level of latency makes it difficult to trust the accuracy of
      // the clock offset sync. A bad sync can make the game unplayable. We'll alert the user.
      console.warn("Network has really high latency, alerting user...");
      alert(`🕖 Your network has significant latency (>150ms).

👾 This will heavily degrade game performance and/or cause glitches.

🛜 Try switching networks, disabling any VPNs, and/or restarting your computer.

🔄 Refresh the page to try again.`);
    } catch (error) {
      console.error("Clock offset sync failed:", error);
    }
  }
}

setTimeout(syncClockOffset, 500); // Run once quickly at the start to set the offset.
setTimeout(syncClockOffset, 15000); // Run again after 15s since initial reading is often bad.
setInterval(syncClockOffset, 1 * 60 * 1000); // Run again every 1 minute(s) to deal with any skew.

// seconds since epoch * 1e18
export function timeWad(): bigint {
  return msToWad(chainTime());
}

export function msToWad(ms: number): bigint {
  return (BigInt(ms) * WAD) / 1000n;
}

export function formatOffset(offset: number): string {
  return (offset >= 0 ? "+" : "") + offset.toFixed(2) + "ms";
}
